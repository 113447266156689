import { Observable, firstValueFrom } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { getBooleanString, isNullOrUndefined } from '../shared/util';
import { Client } from '../entities/household';
import { CustomField } from '../enums/custom-field';
import { CustomFieldService } from './custom-field.service';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourneyClientService } from './journey-client.service';
import { MasterDataService } from './master-data.service';
import { NameValue } from '../entities/name-value';
import { PaymentTypeService } from './payment-type.service';
import { PaymentTypes } from '../enums/payment-type';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';
import { SocialSecurity } from '../entities/social-security';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SocialSecurityService {

  constructor(
    private http: HttpClient,
    private householdService: HouseholdService,
    private customFieldService: CustomFieldService,
    private journeyClientService: JourneyClientService,
    private paymentType: PaymentTypeService
  ) { }

  private readonly customFieldValueTrue = "true";
  private readonly customFieldValueFalse = "false";

  getSocialSecurities(journeyID: string): Observable<SocialSecurity[]> {
    return this.http.get<SocialSecurity[]>(`/api/socialsecurities/${journeyID}/latest`);
  }

  getLatestClientSocialSecurity(journeyID: string | undefined, journeyClientID: string | undefined): Observable<SocialSecurity | undefined> {
    return this.http.get<SocialSecurity | undefined>(`/api/socialsecurities/${journeyID}/${journeyClientID}/latest`);
  }

  saveSocialSecurities(socialSecurities: SocialSecurity[]): Observable<SocialSecurity[]> {
    return this.http.post<SocialSecurity[]>("/api/socialsecurities/", socialSecurities);
  }

  convertBooleanToCustomFieldValueString(value: boolean | null): string | null {
    if (isNullOrUndefined(value)) {
      return null;
    }

    switch (value) {
      case true:
        return this.customFieldValueTrue;
      case false:
        return this.customFieldValueFalse;
      default:
        throw new Error(`The value parameter has an invalid value: ${String(value)}`);
    }
  }

  async refresh(journeyID: string): Promise<void> {
    if (!this.householdService.household) return;
    const socialsecurities = [this.createSocialSecurity(journeyID, this.householdService.household.client, true)];

    if (this.householdService.household.spouse) {
      socialsecurities.push(this.createSocialSecurity(journeyID, this.householdService.household.spouse, false));
    }

    await firstValueFrom(this.saveSocialSecurities(socialsecurities));
  }

  createSocialSecurity(journeyID: string, client: Client, isPrimary: boolean): SocialSecurity {
    const hasSeniorHealthCareCard: string | null = this.customFieldService.getCustomField(CustomField.HasSeniorHealthCareCard, isPrimary)?.value ?? null;
    const providesCaretoAnotherSick: string | null = this.customFieldService.getCustomField(CustomField.ProvidesCareToAnotherSick, isPrimary)?.value ?? null;
    return {
      journeyID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      journeyClientID: client.clientID,
      centreLinkIncomeFrequency: MasterDataService.getNameForIdWithDefault(this.getCentreLinkIncomeFrequency(client.clientID), MasterDataService.getPaymentFrequencyTypes(), null),
      // keep these as null if they are null.
      hasSeniorHealthCareCard: isNullOrUndefined(hasSeniorHealthCareCard) ? null : hasSeniorHealthCareCard === this.customFieldValueTrue,
      providesCaretoAnotherSick: isNullOrUndefined(providesCaretoAnotherSick) ? null : providesCaretoAnotherSick === this.customFieldValueTrue,
      mainCentrelinkBenefitTypeId: client.mainCentrelinkBenefitTypeId
    };
  }

  private getCentreLinkIncomeFrequency(clientID: string): number | null {
    if (this.journeyClientService.primaryJourneyClient()?.journeyClientID === clientID) {
      return this.paymentType.getPrimaryIncomePayment(PaymentTypes.Centrelink)?.frequencyId ?? null;
    }

    return this.paymentType.getSpouseIncomePayment(PaymentTypes.Centrelink)?.frequencyId ?? null;
  }

  async getPdfSection(journeyID: string): Promise<PdfSection> {
    const socialSecuritiesRecords = await firstValueFrom(this.getSocialSecurities(journeyID));

    const pdfSection: PdfSection = {
      breakLine: false,
      pdfSectionType: PdfSectionTypes.Table,
      title: "Social Security",
      content: {
        tableHeaders: [
          {
            name: "Field",
            width: "30%"
          },
          {
            name: this.journeyClientService.getNonNullablePrimary().firstName ?? "Primary",
            width: this.journeyClientService.spouseJourneyClient() ? "30%" : "60%"
          }
        ],
        tableRows: []
      }
    };

    if (this.journeyClientService.spouseJourneyClient()) {
      (pdfSection.content as TableContent).tableHeaders.push(
        {
          name: this.journeyClientService.spouseJourneyClient()?.firstName ?? "Spouse",
          width: "30%"
        }
      );
    }

    const primary = socialSecuritiesRecords.find(x => x.journeyClientID === this.journeyClientService.getNonNullablePrimaryClientID());
    const spouse = socialSecuritiesRecords.find(x => x.journeyClientID === this.journeyClientService.spouseJourneyClient()?.journeyClientID);

    if (primary) {
      const nameValues = SocialSecurityService.getFields(primary, spouse);

      (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x, (this.journeyClientService.spouseJourneyClient() ? true : false))));
    }

    return pdfSection;
  }

  private static getFields(primary: SocialSecurity, spouse?: SocialSecurity): NameValue[] {
    return [
      {
        name: "Are you registered for the Commonwealth Seniors Health Care Card?",
        value: getBooleanString(primary.hasSeniorHealthCareCard),
        spouseValue: getBooleanString(spouse?.hasSeniorHealthCareCard),
      },
      {
        name: "Provides care to another (sick/disabled)?",
        value: getBooleanString(primary.providesCaretoAnotherSick),
        spouseValue: getBooleanString(spouse?.providesCaretoAnotherSick),
      },
      {
        name: "Do you receive Centrelink benefits?",
        value: MasterDataService.getNameForIdWithDefault(primary.mainCentrelinkBenefitTypeId, MasterDataService.getCentrelinkBenefitTypes()),
        spouseValue: MasterDataService.getNameForIdWithDefault(spouse?.mainCentrelinkBenefitTypeId, MasterDataService.getCentrelinkBenefitTypes()),
      }
    ];
  }
}
