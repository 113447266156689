import { Observable, firstValueFrom } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { Client } from '../entities/household';
import { EmploymentDetails } from '../entities/employment-details';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourneyClientService } from './journey-client.service';
import { MasterDataService } from './master-data.service';
import { NameValue } from '../entities/name-value';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';
import moment from 'moment';
import { percentConverter } from '../shared/util';
import { superGuaranteePercentageDecimalPlaces } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class EmploymentDetailsService {

  constructor(
    private http: HttpClient,
    private housholdService: HouseholdService,
    private journeyClientService: JourneyClientService
  ) { }

  getEmploymentDetails(journeyID: string): Observable<EmploymentDetails[]> {
    return this.http.get<EmploymentDetails[]>(`/api/employmentdetails/${journeyID}/latest`);
  }

  getLatestClientEmploymentDetail(journeyID: string | undefined, journeyClientID: string | undefined): Observable<EmploymentDetails | undefined> {
    return this.http.get<EmploymentDetails | undefined>(`/api/employmentdetails/${journeyID}/${journeyClientID}/latest`);
  }

  saveEmploymentDetails(socialSecurities: EmploymentDetails[]): Observable<EmploymentDetails[]> {
    return this.http.post<EmploymentDetails[]>("/api/employmentdetails/", socialSecurities);
  }

  async refresh(journeyID: string): Promise<void> {
    if (!this.housholdService.household) return;
    const employmentDetails = [EmploymentDetailsService.createEmploymentDetails(journeyID, this.housholdService.household.client)];

    if (this.housholdService.household.spouse) {
      employmentDetails.push(EmploymentDetailsService.createEmploymentDetails(journeyID, this.housholdService.household.spouse));
    }

    await firstValueFrom(this.saveEmploymentDetails(employmentDetails));
  }

  static createEmploymentDetails(journeyID: string, client: Client): EmploymentDetails {
    return {
      journeyID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      journeyClientID: client.clientID,
      annualLeaveDays: client.annualLeaveDays,
      employer: client.employer,
      hoursWorkedPerWeek: client.hoursWorkedPerWeek,
      startOfEmployment: client.startOfEmployment,
      longServiceLeaveDays: client.longServiceLeaveDays,
      occupation: client.occupation,
      sickLeaveDays: client.sickLeaveDays,
      superGuaranteePercentage: client.superGuaranteePercentage,
      employmentTypeId: client.employmentTypeId
    };
  }

  async getPdfSection(journeyID: string): Promise<PdfSection> {
    const employmentDetailRecords = await firstValueFrom(this.getEmploymentDetails(journeyID));

    const pdfSection: PdfSection = {
      breakLine: false,
      pdfSectionType: PdfSectionTypes.Table,
      title: "Employment",
      content: {
        tableHeaders: [
          {
            name: "Field",
            width: "30%"
          },
          {
            name: this.journeyClientService.getNonNullablePrimary().firstName ?? "Primary",
            width: this.journeyClientService.spouseJourneyClient() ? "30%" : "60%"
          }
        ],
        tableRows: []
      }
    };

    if (this.journeyClientService.spouseJourneyClient()) {
      (pdfSection.content as TableContent).tableHeaders.push(
        {
          name: this.journeyClientService.spouseJourneyClient()?.firstName ?? "Spouse",
          width: "30%"
        }
      );
    }

    const primary = employmentDetailRecords.find(x => x.journeyClientID === this.journeyClientService.getNonNullablePrimaryClientID());
    const spouse = employmentDetailRecords.find(x => x.journeyClientID === this.journeyClientService.spouseJourneyClient()?.journeyClientID);

    if (primary) {
      const nameValues = EmploymentDetailsService.getFields(primary, spouse);

      (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x, (this.journeyClientService.spouseJourneyClient() ? true : false))));
    }

    return pdfSection;
  }

  private static getFields(primary: EmploymentDetails, spouse?: EmploymentDetails): NameValue[] {
    return [
      {
        name: "Name of employer",
        value: primary.employer,
        spouseValue: spouse?.employer,
      },
      {
        name: "Occupation name",
        value: primary.occupation,
        spouseValue: spouse?.occupation,
      },
      {
        name: "Employment Type",
        value: MasterDataService.getNameForIdWithDefault(primary.employmentTypeId, MasterDataService.getEmploymentTypes(), "-"),
        spouseValue: MasterDataService.getNameForIdWithDefault(spouse?.employmentTypeId, MasterDataService.getEmploymentTypes(), "-"),
      },
      {
        name: "Hours worked per week",
        value: primary.hoursWorkedPerWeek,
        spouseValue: spouse?.hoursWorkedPerWeek,
      },
      {
        name: "Super Guaranteed Percentage ",
        value: primary.superGuaranteePercentage ? `${percentConverter(primary.superGuaranteePercentage, "decimalToPercent", superGuaranteePercentageDecimalPlaces)}%` : "-",
        spouseValue: spouse?.superGuaranteePercentage ? `${percentConverter(spouse.superGuaranteePercentage, "decimalToPercent", superGuaranteePercentageDecimalPlaces)}%` : "-",
      },
      {
        name: "Employment start date",
        value: primary.startOfEmployment ? moment(primary.startOfEmployment).format("DD/MM/yyyy") : null,
        spouseValue: spouse?.startOfEmployment ? moment(spouse.startOfEmployment).format("DD/MM/yyyy") : null
      },
      {
        name: "Annual leave (days)",
        value: primary.annualLeaveDays,
        spouseValue: spouse?.annualLeaveDays,
      },
      {
        name: "Sick leave (days)",
        value: primary.sickLeaveDays,
        spouseValue: spouse?.sickLeaveDays,
      },
      {
        name: "Long service leave (days)",
        value: primary.longServiceLeaveDays,
        spouseValue: spouse?.longServiceLeaveDays,
      }
    ];
  }
}
