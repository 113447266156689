import { Observable, firstValueFrom } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { Client } from '../entities/household';
import { CustomField } from '../enums/custom-field';
import { CustomFieldService } from './custom-field.service';
import { EstatePlanningDetails } from '../entities/estate-planning-details';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourneyClientService } from './journey-client.service';
import { NameValue } from '../entities/name-value';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';
import { getBooleanString } from '../shared/util';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class EstatePlanningDetailsService {

  constructor(
    private http: HttpClient,
    private householdService: HouseholdService,
    private customFieldService: CustomFieldService,
    private journeyClientService: JourneyClientService
  ) { }

  private readonly customFieldValueTrue = "true";

  getEstatePlanningDetails(journeyID: string): Observable<EstatePlanningDetails[]> {
    return this.http.get<EstatePlanningDetails[]>(`/api/estateplanningdetails/${journeyID}/latest`);
  }

  getLatestClientEstatePlanningDetails(journeyID: string | undefined, journeyClientID: string | undefined): Observable<EstatePlanningDetails | undefined> {
    return this.http.get<EstatePlanningDetails | undefined>(`/api/estateplanningdetails/${journeyID}/${journeyClientID}/latest`);
  }

  saveEstatePlanningDetails(estatePlanningDetails: EstatePlanningDetails[]): Observable<EstatePlanningDetails[]> {
    return this.http.post<EstatePlanningDetails[]>("/api/estateplanningdetails/", estatePlanningDetails);
  }

  async refresh(journeyID: string): Promise<void> {
    if (!this.householdService.household) return;
    const estatePlanningDetails = [this.createEstatePlanningDetails(journeyID, this.householdService.household.client, true)];

    if (this.householdService.household.spouse) {
      estatePlanningDetails.push(this.createEstatePlanningDetails(journeyID, this.householdService.household.spouse, false));
    }

    await firstValueFrom(this.saveEstatePlanningDetails(estatePlanningDetails));
  }

  createEstatePlanningDetails(journeyID: string, client: Client, isPrimary: boolean): EstatePlanningDetails {
    return {
      journeyID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      journeyClientID: client.clientID,
      dateOfWill: client.dateOfWill,
      hasWill: client.hasWill,
      hasEnduringPowerOfAttorney: client.hasEnduringPowerOfAttorney,
      hasGeneralPowerOfAttorney: client.hasGeneralPowerOfAttorney,
      hasMedicalPowerOfAttorney: client.hasMedicalPowerOfAttorney,
      hasTestamentaryTrust: this.customFieldService
        .getCustomField(CustomField.DoYouHaveTestamentaryTrust, isPrimary)?.value === this.customFieldValueTrue
    };
  }

  async getPdfSection(journeyID: string): Promise<PdfSection> {
    const estatePlanningRecords = await firstValueFrom(this.getEstatePlanningDetails(journeyID));

    const pdfSection: PdfSection = {
      breakLine: false,
      pdfSectionType: PdfSectionTypes.Table,
      title: "Estate Planning",
      content: {
        tableHeaders: [
          {
            name: "Field",
            width: "30%"
          },
          {
            name: this.journeyClientService.getNonNullablePrimary().firstName ?? "Primary",
            width: this.journeyClientService.spouseJourneyClient() ? "30%" : "60%"
          }
        ],
        tableRows: []
      }
    };

    if (this.journeyClientService.spouseJourneyClient()) {
      (pdfSection.content as TableContent).tableHeaders.push(
        {
          name: this.journeyClientService.spouseJourneyClient()?.firstName ?? "Spouse",
          width: "30%"
        }
      );
    }

    const primary = estatePlanningRecords.find(x => x.journeyClientID === this.journeyClientService.getNonNullablePrimaryClientID());
    const spouse = estatePlanningRecords.find(x => x.journeyClientID === this.journeyClientService.spouseJourneyClient()?.journeyClientID);

    if (primary) {
      const nameValues = EstatePlanningDetailsService.getFields(primary, spouse);

      (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x, (this.journeyClientService.spouseJourneyClient() ? true : false))));
    }

    return pdfSection;
  }

  private static getFields(primary: EstatePlanningDetails, spouse?: EstatePlanningDetails): NameValue[] {
    return [
      {
        name: "Current will in place?",
        value: getBooleanString(primary.hasWill),
        spouseValue: getBooleanString(spouse?.hasWill),
      },
      {
        name: "Date of will",
        value: primary.hasWill ? moment(primary.dateOfWill).format("dd/MM/yyyy") : null,
        spouseValue: spouse && spouse.hasWill ? moment(spouse.dateOfWill).format("dd/MM/yyyy") : null
      },
      {
        name: "Enduring power of attorney in place?",
        value: getBooleanString(primary.hasEnduringPowerOfAttorney),
        spouseValue: getBooleanString(spouse?.hasEnduringPowerOfAttorney),
      },
      {
        name: "General power of attorney in place?",
        value: getBooleanString(primary.hasGeneralPowerOfAttorney),
        spouseValue: getBooleanString(spouse?.hasGeneralPowerOfAttorney),
      },
      {
        name: "Medical power of attorney in place?",
        value: getBooleanString(primary.hasMedicalPowerOfAttorney),
        spouseValue: getBooleanString(spouse?.hasMedicalPowerOfAttorney),
      },
      {
        name: "Do you have a testamentary trust?",
        value: getBooleanString(primary.hasTestamentaryTrust),
        spouseValue: getBooleanString(spouse?.hasTestamentaryTrust),
      }
    ];
  }
}
