import { HouseholdService } from './household.service';
import { Injectable } from '@angular/core';
import { Payment } from '../entities/household';
import { PaymentFrequencies } from '../enums/payment-frequency';
import { PaymentTypes } from '../enums/payment-type';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService {

  constructor(private householdService: HouseholdService) { }

  getIncomePayment(paymentType: PaymentTypes, isPrimary: boolean): Payment | null {
    return isPrimary ? this.getPrimaryIncomePayment(paymentType) : this.getSpouseIncomePayment(paymentType);
  }

  getPrimaryIncomePayment(paymentType: PaymentTypes): Payment | null {
    if(!this.householdService.household) return null;

    const payment =  this.householdService.household.client.incomes.find(x => x.typeId === paymentType.valueOf());

    if (!payment) {
      return PaymentTypeService.createPayment(paymentType);
    }

    return payment;
  }

  getSpouseIncomePayment(paymentType: PaymentTypes): Payment | null {
    if(!this.householdService.household?.spouse) return null;

    const payment =  this.householdService.household.spouse.incomes.find(x => x.typeId === paymentType.valueOf());

    if (!payment) {
      return PaymentTypeService.createPayment(paymentType);
    }

    return payment;
  }

  static createPayment(paymentType: PaymentTypes): Payment {
    return {
      typeId: paymentType.valueOf(),
      frequencyId: PaymentFrequencies.Annually,
      value: 0,
      id : null
    }
  }
}
