import { Address, Client } from '../entities/household';
import { Injectable, signal } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { ContactDetails } from '../entities/contact-details';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { JourneyClientService } from './journey-client.service';
import { MasterDataService } from './master-data.service';
import { NameValue } from '../entities/name-value';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ContactDetailsService {

  constructor(
    private http: HttpClient,
    private householdService: HouseholdService,
    private journeyClientService: JourneyClientService
  ) { }

  contactDetails = signal<ContactDetails[] | null>(null);

  getContactDetails(journeyID: string): Observable<ContactDetails[] | null> {
    return this.http.get<ContactDetails[] | null>(`/api/contactDetails/${journeyID}/latest`);
  }

  getLatestClientContactDetails(journeyID: string | undefined, journeyClientID: string | undefined): Observable<ContactDetails | undefined> {
    return this.http.get<ContactDetails | undefined>(`/api/contactDetails/${journeyID}/${journeyClientID}/latest`);
  }

  saveContactDetails(contactDetails: ContactDetails[]): Observable<ContactDetails[]> {
    return this.http.post<ContactDetails[]>("/api/contactDetails/", contactDetails);
  }

  async refresh(journeyID: string): Promise<void> {
    if (!this.householdService.household) return;

    const contactDetails = await firstValueFrom(this.getContactDetails(journeyID)) ?? [];

    let primaryClientContactDetails = contactDetails.find(x => x.journeyClientID === this.householdService.household?.client.clientID);

    if (!primaryClientContactDetails) {
      primaryClientContactDetails = ContactDetailsService.createContactDetail(journeyID, this.householdService.household.client.clientID);

      contactDetails.push(primaryClientContactDetails);
    }

    ContactDetailsService.updateContactDetails(primaryClientContactDetails, this.householdService.household.client, this.householdService.household.residentialAddress);

    if (this.householdService.household.spouse) {
      let spouseClientContactDetails = contactDetails.find(x => x.journeyClientID === this.householdService.household?.spouse?.clientID);

      if (!spouseClientContactDetails) {
        spouseClientContactDetails = ContactDetailsService.createContactDetail(journeyID, this.householdService.household.spouse.clientID);
        
        contactDetails.push(spouseClientContactDetails);
      }      

      ContactDetailsService.updateContactDetails(spouseClientContactDetails, this.householdService.household.spouse, this.householdService.household.residentialAddress);
    }

    await firstValueFrom(this.saveContactDetails(contactDetails));
  }

  static createContactDetail(journeyID?: string, journeyClientID?: string): ContactDetails {
    return {
      journeyID,
      journeyClientID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate()
    };
  }

  createAndUpdateContactDetail(journeyID?: string, journeyClientID?: string): ContactDetails {
    const contactDetail = {
      journeyID,
      journeyClientID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate()
    };

    if (this.householdService.household) {
      const client = this.householdService.household.client.clientID === journeyClientID ? this.householdService.household.client : this.householdService.household.spouse;

      if (client) {
        ContactDetailsService.updateContactDetails(contactDetail, client, this.householdService.household.residentialAddress);
      }
    }

    return contactDetail;
  }

  static updateContactDetails(contactDetails: ContactDetails, client: Client, address?: Address): void {
    contactDetails.homePhone = client.homePhone;
    contactDetails.workPhone = client.workPhone;
    contactDetails.mobilePhone = client.mobilePhone;
    contactDetails.email = client.email;
    contactDetails.address1 = address?.address1;
    contactDetails.address2 = address?.address2
    contactDetails.stateID = address?.stateID;
    contactDetails.state = MasterDataService.getAddressStates().find(x => x.iD === address?.stateID)?.name;
    contactDetails.postCode = address?.postCode;
    contactDetails.suburb = address?.suburb;
  }

  async getPdfSections(journeyID: string): Promise<PdfSection[]> {
    this.contactDetails.set(await firstValueFrom(this.getContactDetails(journeyID)));
    return [this.getContactSection(), this.getResidentialSection()];
  }

  private getContactSection(): PdfSection {
    const pdfSection: PdfSection = {
      breakLine: false,
      pdfSectionType: PdfSectionTypes.Table,
      title: "Contact",
      content: {
        tableHeaders: [
          {
            name: "Field",
            width: "30%"
          },
          {
            name: this.journeyClientService.getNonNullablePrimary().firstName ?? "Primary",
            width: this.journeyClientService.spouseJourneyClient() ? "30%" : "60%"
          }
        ],
        tableRows: []
      }
    };

    if (this.journeyClientService.spouseJourneyClient()) {
      (pdfSection.content as TableContent).tableHeaders.push(
        {
          name: this.journeyClientService.spouseJourneyClient()?.firstName ?? "Spouse",
          width: "30%"
        }
      );
    }

    const primary = this.contactDetails()?.find(x => x.journeyClientID === this.journeyClientService.getNonNullablePrimaryClientID());
    const spouse = this.contactDetails()?.find(x => x.journeyClientID === this.journeyClientService.spouseJourneyClient()?.journeyClientID);

    if (primary) {
      const nameValues = ContactDetailsService.getContactFields(primary, spouse);

      (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x, (this.journeyClientService.spouseJourneyClient() ? true : false))));
    }

    return pdfSection;
  }

  private getResidentialSection(): PdfSection {
    const pdfSection: PdfSection = {
      breakLine: true,
      pdfSectionType: PdfSectionTypes.Table,
      title: "Residential address",
      content: {
        tableHeaders: [
          {
            name: "Field",
            width: "30%"
          },
          {
            name: this.journeyClientService.getNonNullablePrimary().firstName ?? "Primary",
            width: "60%"
          }
        ],
        tableRows: []
      }
    };

    const primary = this.contactDetails()?.find(x => x.journeyClientID === this.journeyClientService.getNonNullablePrimaryClientID());

    if (primary) {
      const nameValues = ContactDetailsService.getResidentialFields(primary);

      (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x)));
    }

    return pdfSection;
  }

  private static getContactFields(contactDetails: ContactDetails, spouseContactDetails?: ContactDetails): NameValue[] {
    return [
      {
        name: "Home phone",
        value: contactDetails.homePhone,
        spouseValue: spouseContactDetails?.homePhone,
      },
      {
        name: "Work phone",
        value: contactDetails.workPhone,
        spouseValue: spouseContactDetails?.workPhone,
      },
      {
        name: "Mobile phone",
        value: contactDetails.mobilePhone,
        spouseValue: spouseContactDetails?.mobilePhone,
      },
      {
        name: "Email address",
        value: contactDetails.email,
        spouseValue: spouseContactDetails?.email,
      },
    ];
  }

  private static getResidentialFields(contactDetails: ContactDetails): NameValue[] {
    return [
      {
        name: "Address Line 1",
        value: contactDetails.address1
      },
      {
        name: "Address Line 2",
        value: contactDetails.address2
      },
      {
        name: "Suburb",
        value: contactDetails.suburb
      },
      {
        name: "Postcode",
        value: contactDetails.postCode
      },
      {
        name: "State",
        value: contactDetails.state
      }
    ];
  }
}
