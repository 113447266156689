import { Injectable, signal } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { createGuid, isGuid, one } from '../shared/util';
import { Dependant } from '../entities/household';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { JourneyClientService } from './journey-client.service';
import { JourneyDependant } from '../entities/journey-dependant';
import { JourneyService } from './journey.service';
import { NameValue } from '../entities/name-value';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class JourneyDependantService {

  constructor(
    private http: HttpClient,
    private householdService: HouseholdService,
    private journeyService: JourneyService,
    private journeyClientService: JourneyClientService
  ) { }

  journeyDependants = signal<JourneyDependant[]>([]);

  getJourneyDependants(previousJourneyInstanceID?: string): Observable<JourneyDependant[]> {
    const journeyID = this.journeyService.getNonNullableJourney().journeyID;
    const journeyInstanceId = previousJourneyInstanceID ?? this.journeyService.getNonNullableJourney().journeyInstanceID;
    return this.http.get<JourneyDependant[]>(`/api/journeydependants/${journeyID}/${journeyInstanceId}/latest`);
  }

  saveJourneyDependants(journeyDependants: JourneyDependant[]): Observable<JourneyDependant[]> {
    return this.http.post<JourneyDependant[]>("/api/journeydependants/", journeyDependants);
  }

  async updateJourneyDependant(journeyDependant: JourneyDependant): Promise<void> {
    this.journeyDependants
      .update(x => [...x.filter(y => y.dependantID !== journeyDependant.dependantID), journeyDependant]);
    await firstValueFrom(this.saveJourneyDependants([journeyDependant]));
  }

  async addJourneyDependant(journeyDependant: JourneyDependant): Promise<void> {
    journeyDependant.dependantID = createGuid();
    this.journeyDependants.update(x => [...x, journeyDependant]);
    await firstValueFrom(this.saveJourneyDependants([journeyDependant]));
  }

  async deleteJourneyDependant(journeyDependant: JourneyDependant): Promise<void> {
    journeyDependant.isDeleted = true;
    this.journeyDependants.update(x => x.filter(y => y.dependantID !== journeyDependant.dependantID));
    await firstValueFrom(this.saveJourneyDependants([journeyDependant]));
  }

  async refresh(): Promise<void> {
    if (!this.householdService.household?.dependants) return;
    const journeyDependants = this.householdService.household.dependants.map(x => this.createJourneyDependants(x));
    this.journeyDependants.set(journeyDependants);

    if (journeyDependants[0]) {
      await firstValueFrom(this.saveJourneyDependants(journeyDependants));
    }
  }

  createJourneyDependants(dependant?: Dependant): JourneyDependant {
    return {
      journeyID: this.journeyService.getNonNullableJourney().journeyID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      birthDate: dependant?.birthDate,
      dependantAge: dependant?.dependantAge,
      dependantID: dependant?.id ? dependant.id.toString() : createGuid(),
      journeyInstanceID: this.journeyService.getNonNullableJourney().journeyInstanceID,
      male: dependant?.male ?? false,
      name: dependant?.name,
      isDeleted: false,
    };
  }

  static getAge(journeyDependant: JourneyDependant): number | null {
    if (!journeyDependant.birthDate) {
      return null;
    }
    return moment().diff(journeyDependant.birthDate, 'years');
  }

  updateDependants(): void {
    if (!this.householdService.household) return;

    this.householdService.household.dependants = this.journeyDependants()
      .map(x => ({
        id: isGuid(x.dependantID) ? null : Number(x.dependantID),
        name: x.name,
        male: x.male,
        birthDate: x.birthDate,
        dependantAge: x.dependantAge,
      }));
  }

  getPdfSections(): PdfSection[] {
    const pdfSections: PdfSection[] = [];

    for (const [index, journeyDependant] of this.journeyDependants().entries()) {
      const pdfSection: PdfSection = {
        breakLine: false,
        pdfSectionType: PdfSectionTypes.Table,
        title: `Dependant ${index + one}`,
        content: {
          tableHeaders: [
            {
              name: "Field",
              width: "30%"
            },
            {
              name: "",
              width: "60%"
            }
          ],
          tableRows: []
        }
      };

      const nameValues = JourneyDependantService.getJourneyDependantFields(journeyDependant);

      (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x)));

      pdfSections.push(pdfSection);
    }

    return pdfSections;
  }

  async loadJourneyDependants(previousJourneyInstanceID: string): Promise<void> {
    const items = await firstValueFrom(this.getJourneyDependants(previousJourneyInstanceID));

    const now = moment().utc().toDate();
    for (const item of items) {
      item.journeyInstanceID = this.journeyService.getNonNullableJourney().journeyInstanceID;
      item.lastModified = now;
      item.created = now;
    }

    await firstValueFrom(this.saveJourneyDependants(items));

    this.journeyDependants.set(items);
  }

  private static getJourneyDependantFields(journeyDependant: JourneyDependant): NameValue[] {
    return [
      {
        name: "Name",
        value: journeyDependant.name,
      },
      {
        name: "Gender",
        value: journeyDependant.male ? "Male" : "Female"
      },
      {
        name: "Date of birth",
        value: moment(journeyDependant.birthDate).format("DD/MM/yyyy")
      },
      {
        name: "Age",
        value: JourneyDependantService.getAge(journeyDependant)
      },
      {
        name: "Age dependent to",
        value: journeyDependant.dependantAge
      }
    ];
  }
}