import { Observable, firstValueFrom } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { Client } from '../entities/household';
import { HealthInsurance } from '../entities/health-insurance';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourneyClientService } from './journey-client.service';
import { NameValue } from '../entities/name-value';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';
import { getBooleanString } from '../shared/util';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HealthInsuranceService {

  constructor(
    private http: HttpClient,
    private householdService: HouseholdService,
    private journeyClientService: JourneyClientService
  ) { }

  getHealthInsurances(journeyID: string): Observable<HealthInsurance[]> {
    return this.http.get<HealthInsurance[]>(`/api/healthinsurances/${journeyID}/latest`);
  }

  getLatestClientHealthInsurance(journeyID: string | undefined, journeyClientID: string | undefined): Observable<HealthInsurance | undefined> {
    return this.http.get<HealthInsurance | undefined>(`/api/healthinsurances/${journeyID}/${journeyClientID}/latest`);
  }

  saveHealthInsurances(healthInsurances: HealthInsurance[]): Observable<HealthInsurance[]> {
    return this.http.post<HealthInsurance[]>("/api/healthinsurances/", healthInsurances);
  }

  async refresh(journeyID: string): Promise<void> {
    if (!this.householdService.household) return;

    // Since some of the data is not stored in AOS, we need to get it from the DEA database.
    const healthDataFromDea = await firstValueFrom(this.getHealthInsurances(journeyID));

    const healthInsurances = [HealthInsuranceService.createHealthInsurance(journeyID, this.householdService.household.client, healthDataFromDea)];

    if (this.householdService.household.spouse) {
      healthInsurances.push(HealthInsuranceService.createHealthInsurance(journeyID, this.householdService.household.spouse, healthDataFromDea));
    }

    await firstValueFrom(this.saveHealthInsurances(healthInsurances));
  }

  static createHealthInsurance(journeyID: string, client: Client, healthDataFrmDeaDb: HealthInsurance[]): HealthInsurance {
    return {
      journeyID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      journeyClientID: client.clientID,
      hasPrivateHealthInsurance: client.hasPrivateHealthInsurance,
      privateHealthFund: client.privateHealthFund,
      healthReferralSentAndConsentGiven: healthDataFrmDeaDb.find(x => x.journeyClientID === client.clientID)?.healthReferralSentAndConsentGiven ?? null
    };
  }

  async getPdfSection(journeyID: string): Promise<PdfSection> {
    const healthInsuranceRecords = await firstValueFrom(this.getHealthInsurances(journeyID));

    const pdfSection: PdfSection = {
      breakLine: false,
      pdfSectionType: PdfSectionTypes.Table,
      title: "Health Insurance",
      content: {
        tableHeaders: [
          {
            name: "Field",
            width: "30%"
          },
          {
            name: this.journeyClientService.getNonNullablePrimary().firstName ?? "Primary",
            width: this.journeyClientService.spouseJourneyClient() ? "30%" : "60%"
          }
        ],
        tableRows: []
      }
    };

    if (this.journeyClientService.spouseJourneyClient()) {
      (pdfSection.content as TableContent).tableHeaders.push(
        {
          name: this.journeyClientService.spouseJourneyClient()?.firstName ?? "Spouse",
          width: "30%"
        }
      );
    }

    const primary = healthInsuranceRecords.find(x => x.journeyClientID === this.journeyClientService.getNonNullablePrimaryClientID());
    const spouse = healthInsuranceRecords.find(x => x.journeyClientID === this.journeyClientService.spouseJourneyClient()?.journeyClientID);

    if (primary) {
      const nameValues = HealthInsuranceService.getFields(primary, spouse);

      (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x, (this.journeyClientService.spouseJourneyClient() ? true : false))));
    }

    return pdfSection;
  }

  private static getFields(primary: HealthInsurance, spouse?: HealthInsurance): NameValue[] {
    return [
      {
        name: "Private Health?",
        value: getBooleanString(primary.hasPrivateHealthInsurance),
        spouseValue: getBooleanString(spouse?.hasPrivateHealthInsurance),
      },
      {
        name: "Private health fund",
        value: primary.privateHealthFund,
        spouseValue: spouse?.privateHealthFund,
      },
      {
        name: "Consent for health referral",
        value: getBooleanString(primary.healthReferralSentAndConsentGiven),
        spouseValue: getBooleanString(spouse?.healthReferralSentAndConsentGiven),
      }
    ];
  }
}
